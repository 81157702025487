import { IDataHealthState } from '../../features/ApiClient/ApiClient'

export const DataHealthTrafficLight = (props: IDataHealthState) => {
    const { state, label } = props;

    const checkmarkIcon = `<svg width="9" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.931641 3L2.94422 5L6.96938 1" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/></svg>`;
    const exclamationIcon = `<svg width="3" height="11" viewBox="0 0 3 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.64322 7.256C1.44722 7.256 1.27455 7.23267 1.12522 7.186C1.07855 6.58867 1.03189 6.00533 0.985219 5.436C0.947885 4.85733 0.905885 4.33 0.859219 3.854C0.821885 3.36867 0.793885 2.95333 0.775219 2.608C0.756552 2.25333 0.747219 1.992 0.747219 1.824C0.747219 1.49733 0.812552 1.25 0.943219 1.082C1.08322 0.914 1.31655 0.83 1.64322 0.83C1.96989 0.83 2.19855 0.914 2.32922 1.082C2.46922 1.25 2.53922 1.49733 2.53922 1.824C2.53922 1.992 2.52989 2.25333 2.51122 2.608C2.49255 2.95333 2.45989 3.36867 2.41322 3.854C2.37589 4.33 2.33389 4.85733 2.28722 5.436C2.24989 6.00533 2.20789 6.58867 2.16122 7.186C2.02122 7.23267 1.84855 7.256 1.64322 7.256ZM1.65722 10.168C1.34922 10.168 1.11122 10.0793 0.943219 9.902C0.784552 9.72467 0.705219 9.496 0.705219 9.216C0.705219 8.94533 0.803219 8.72133 0.999219 8.544C1.19522 8.35733 1.41455 8.264 1.65722 8.264C1.97455 8.264 2.21722 8.35267 2.38522 8.53C2.55322 8.698 2.63722 8.92667 2.63722 9.216C2.63722 9.48667 2.53922 9.71533 2.34322 9.902C2.14722 10.0793 1.91855 10.168 1.65722 10.168Z" fill="white"/></svg>`;
    const timesIcon = `<svg width="8" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.09982 3L6.81628 1.29426C7.02692 1.08494 7.02692 0.745568 6.81628 0.53608L6.43481 0.156989C6.22417 -0.0523295 5.88266 -0.0523295 5.67186 0.156989L3.95539 1.86273L2.23892 0.156989C2.02829 -0.0523295 1.68678 -0.0523295 1.47597 0.156989L1.0945 0.53608C0.883865 0.745398 0.883865 1.08477 1.0945 1.29426L2.81097 3L1.0945 4.70574C0.883865 4.91506 0.883865 5.25443 1.0945 5.46392L1.47597 5.84301C1.68661 6.05233 2.02829 6.05233 2.23892 5.84301L3.95539 4.13727L5.67186 5.84301C5.88249 6.05233 6.22417 6.05233 6.43481 5.84301L6.81628 5.46392C7.02692 5.2546 7.02692 4.91523 6.81628 4.70574L5.09982 3Z" fill="white"/></svg>`;

    const computeIcon = (dataHealthState: string) => {
        const iconDict = {
            'good': checkmarkIcon,
            'warning': exclamationIcon,
            'bad': timesIcon,
            'inactive': timesIcon
        }
        return iconDict[dataHealthState as keyof typeof iconDict];
    };

    const computeBackgroundColor = (dataHealthState: string) => {
        const backgroundColorDict = {
            'good': '#0FC580',      // $attention-green - future-green
            'warning': '#CE9048',   // $attention-yellow - gold
            'bad': '#FF281C',       // $attention-red - still original strong red for attention (unless they want iron-purple?)
            'inactive': '#808080',  // $attention-gray
        }
        return backgroundColorDict[dataHealthState as keyof typeof backgroundColorDict];
    };

    return <article className="fls-data-health-traffic-lights">
        <div className="fls-data-health-traffic-lights__background" style={{ backgroundColor: computeBackgroundColor(state ?? '') }}>
            <span dangerouslySetInnerHTML={{ __html: computeIcon(state ?? '') }}></span>
        </div>

        <p className="fls-font__small-regular">{label}</p>
    </article>;
};
