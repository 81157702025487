import { useEffect } from 'react'
import { useStores } from '../../stores/StoreDistributor'
import { Button } from '../Button/Button'

interface IButtonConfig {
    type: string,
    label: string,
    action: () => void,
}

export interface IModalRootWrapperProps {
    title: string,
    slotComponent: JSX.Element,
    buttonConfig: IButtonConfig[],
    modalClosedCallback?: () => void,
}

export const ModalRootWrapper = (props: IModalRootWrapperProps) => {
    const { title, slotComponent, buttonConfig, modalClosedCallback } = props;
    const { mainStore } = useStores();

    const closeModal = () => {

        if (modalClosedCallback != null) {
            modalClosedCallback();
        } else {
            mainStore.setModalDisplayState(false);
        }
    };

    const handleKeyUpEvent = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUpEvent);
    }, [])
    
    if (mainStore.modalDisplayState) {
        return (
            <div className="fls-modal">
                <div className="fls-modal__background" onClick={closeModal}></div>
                <article className="fls-modal__content">
                    <div className="fls-modal__header">
                        <h2 className="fls-font__xsmall-title">{title}</h2>
                        <button className="fls-button__modal-close" onClick={closeModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="fls-modal__slot-component">
                        {slotComponent}
                    </div>
                    <div className="fls-modal__action-buttons">
                        {buttonConfig.map((button, index) => {
                            return button.action && <Button key={index} type={button.type} text={button.label} action={button.action.bind(this, false)} />
                        })}
                    </div>
                </article>
            </div>
        );
    } else {
        return <template></template>;
    }
}
