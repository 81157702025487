

export const settingsIcon = () => {
    return (
        <svg
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_229_11379)">
                <path
                    d="M8.17396 3.41763C8.51121 2.02746 10.4888 2.02746 10.826 3.41763C10.8766 3.62647 10.9758 3.82041 11.1156 3.98367C11.2553 4.14692 11.4316 4.27488 11.6301 4.35713C11.8286 4.43938 12.0438 4.47359 12.258 4.45698C12.4722 4.44037 12.6795 4.37341 12.863 4.26154C14.0845 3.51737 15.4834 4.91546 14.7393 6.13779C14.6275 6.32119 14.5607 6.52835 14.5441 6.74245C14.5275 6.95654 14.5617 7.17153 14.6439 7.36993C14.726 7.56833 14.8538 7.74454 15.0169 7.88425C15.18 8.02396 15.3737 8.12322 15.5824 8.17396C16.9725 8.51121 16.9725 10.4888 15.5824 10.826C15.3735 10.8766 15.1796 10.9758 15.0163 11.1156C14.8531 11.2553 14.7251 11.4316 14.6429 11.6301C14.5606 11.8286 14.5264 12.0438 14.543 12.258C14.5596 12.4722 14.6266 12.6795 14.7385 12.863C15.4826 14.0845 14.0845 15.4834 12.8622 14.7393C12.6788 14.6275 12.4717 14.5607 12.2576 14.5441C12.0435 14.5275 11.8285 14.5617 11.6301 14.6439C11.4317 14.726 11.2555 14.8538 11.1158 15.0169C10.976 15.18 10.8768 15.3737 10.826 15.5824C10.4888 16.9725 8.51121 16.9725 8.17396 15.5824C8.12336 15.3735 8.02416 15.1796 7.88444 15.0163C7.74472 14.8531 7.56842 14.7251 7.3699 14.6429C7.17138 14.5606 6.95624 14.5264 6.742 14.543C6.52776 14.5596 6.32047 14.6266 6.137 14.7385C4.91546 15.4826 3.51658 14.0845 4.26075 12.8622C4.37246 12.6788 4.43931 12.4717 4.45588 12.2576C4.47246 12.0435 4.43828 11.8285 4.35612 11.6301C4.27397 11.4317 4.14616 11.2555 3.98309 11.1158C3.82002 10.976 3.62628 10.8768 3.41763 10.826C2.02746 10.4888 2.02746 8.51121 3.41763 8.17396C3.62647 8.12336 3.82041 8.02416 3.98367 7.88444C4.14692 7.74472 4.27488 7.56842 4.35713 7.3699C4.43938 7.17138 4.47359 6.95624 4.45698 6.742C4.44037 6.52776 4.37341 6.32047 4.26154 6.137C3.51737 4.91546 4.91546 3.51658 6.13779 4.26075C6.92946 4.74208 7.95546 4.31617 8.17396 3.41763Z"
                    stroke="#062549"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 11.875C10.8117 11.875 11.875 10.8117 11.875 9.5C11.875 8.18832 10.8117 7.125 9.5 7.125C8.18832 7.125 7.125 8.18832 7.125 9.5C7.125 10.8117 8.18832 11.875 9.5 11.875Z"
                    stroke="#062549"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_229_11379">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
