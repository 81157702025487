
export const fetchData = async (url : string) => {  
    let data; 

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
            }
        })
        if (!response.ok) throw new Error(response.statusText)
        data = response.json()

    } catch (e) {
        console.log(e)
    }
    
    return data
}
