import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import DetailStore from './DetailStore';
import ExportStore from './ExportStore';
import MainStore from './MainStore';
import SavedSearchStore from './SavedSearchStore';
import PartSearchStore from './PartSearchStore';
import LocaleSettingsStore from './LocaleSettingsStore';

// RootStore encapsulates other store to allow for store-to-store comminication
export class RootStore {
    mainStore = new MainStore();
    routerStore = new RouterStore();

    partSearchStore = new PartSearchStore(this);
    savedSearchesStore = new SavedSearchStore(this);
    exportStore = new ExportStore(this);
    detailStore = new DetailStore(this);
    localeSettingsStore = new LocaleSettingsStore(this);
}

export const storesContext = React.createContext(new RootStore())

export const useStores = () => React.useContext(storesContext)
