export const FLSBoxlogo = (width: number | string) => {
    const fillWhite = { fill: '#FFFFFF' }
    return (
        <div style={{ width: width, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: '#002a54', padding: '13% 8%' }}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 141.7 54.3" className="st0" style={fillWhite} xmlSpace="preserve" enableBackground="new 0 0 141.7 54.3">
                <g>
                    <path className="st0" d="M41.7,24.1v9.9H17v19.7H0c0-3.5,0-43.6,0-43.6v-0.1c0-5.6,3.8-9.4,9.5-9.5l33,0l0,9.9H17v13.6H41.7z" />
                    <polygon className="st0" points="66.7,41.5 66.7,0.6 49.7,0.6 49.7,53.7 66.6,53.7 91.5,53.7 91.5,41.5"/>
                    <path className="st0" d="M114.5,31.5c5.3,1.1,10.3,2.1,10.3,6.3c0,2.1-1.4,5.6-11.1,5.6c-5.7,0-10.7-1.3-15.8-3.1l-0.5-0.2v12.4h0
                    c11.6,1.8,14.6,1.8,19.3,1.8c9.1,0,25.2-3.5,25.2-17.9c0-11.5-9.7-13.7-18.2-15.4c-5.5-1.1-10.2-2-10.2-6.1c0-1.2,0.5-2.3,1.3-3.2
                    c2.1-2,5.9-2.2,9.4-2.2c4.8,0,9.6,0.7,14.1,2.1V1.6c-5.3-1-10.9-1.6-16-1.6c-13.9,0-25.9,3.5-25.9,15.9
                    C96.4,28.4,107.6,30.1,114.5,31.5z"
                    />
                </g>
            </svg>
        </div>
    );
};
