import React, { useState } from 'react'
import { Button } from '../../Button/Button'
import { spyGlassIcon } from '../../../foundation/svg/spyglass-icon'

interface IProps {
    placeholder: string,
    callBack: Function,
    autoFocus: boolean,
    type: string,
    label?: string
}


export const TextInput = (props: IProps) => {
    const { placeholder, callBack, autoFocus, type, label } = props
    const [inputValue, setInputValue] = useState('')

    const handleChange = (event : React.FormEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value)
        if (type !== 'main-search') {
            callBack(event.currentTarget.value)
        }
    }

    const inputTypeModifierClass = (type : string) => {
        return `fls-text-input__input-container--${type}`
    }
    
    return (
        <div className="fls-text-input">
            <div className={"fls-text-input__input-container " + inputTypeModifierClass(type)} tabIndex={0}>
                {type === 'main-search' && <div className="fls-text-input__icon">{spyGlassIcon()}</div>}
                {type !== 'main-search' && <label className="fls-font__xsmall-regular" htmlFor="fls-text-input-id">{label ?? ''}</label>}
                <input className="fls-text-input__input-field fls-font__medium-regular" 
                    id="fls-text-input-id"
                    autoFocus={autoFocus}
                    placeholder={placeholder} 
                    onKeyPress={(e) => e.key === 'Enter' ? callBack(inputValue) : null}
                    onInput={(e) => handleChange(e)} 
                    value={inputValue}
                    />
            </div>
            {type === 'main-search' && <Button type="primary" text="Search" action={callBack.bind(this, inputValue)} />}
        </div>
    )
}