import { spyGlassIcon } from '../../../foundation/svg/spyglass-icon'
import PartSearchStore from '../../../stores/PartSearchStore'
import { Button } from '../../Button/Button'

interface SearchTextInputProps {
    searchStore: PartSearchStore,
    activationCallback: () => void,
    placeholder: string,
    autoFocus: boolean,
}

export const SearchTextInput = (props: SearchTextInputProps) => {
    const { searchStore, activationCallback, placeholder, autoFocus } = props;

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        searchStore.searchString = event.currentTarget.value;
    }

    return (
        <div className="fls-text-input">
            <div className={"fls-text-input__input-container " + "fls-text-input__input-container--main-search"} tabIndex={0}>
                <div className="fls-text-input__icon">{spyGlassIcon()}</div>
                <input className="fls-text-input__input-field fls-font__medium-regular" 
                    id="fls-text-input-id"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    onKeyPress={(e) => e.key === 'Enter' ? activationCallback() : null}
                    onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        handleChange(e);
                    }} 
                    value={searchStore.searchString}
                    />
            </div>
            <Button type="primary" text="Search" action={activationCallback} />
        </div>
    )
}
