export const editIcon = () => {
    return <svg
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_95_6048)">
            <path
                d="M5 14.8576H7.83333L15.2708 7.42014C15.6466 7.04441 15.8576 6.53482 15.8576 6.00347C15.8576 5.47212 15.6466 4.96253 15.2708 4.5868C14.8951 4.21108 14.3855 4 13.8542 4C13.3228 4 12.8132 4.21108 12.4375 4.5868L5 12.0243V14.8576Z"
                stroke="#062549"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.7295 5.29492L14.5628 8.12826"
                stroke="#062549"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_95_6048">
                <rect width="17" height="17" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
