import { makeAutoObservable } from "mobx";
import { RootStore } from "./StoreDistributor";
import { SpcFeedback_ModalContent, NominatePartModalContent } from "../components/Modal/ModalComponents";

export default class DetailStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    TriggerOutlook = () => {
        const spcAdminEmail = "SPCTeams@flsmidth.com";
        const mailSubject = "Adding Parts to Standard Parts Catalogue";
        const emailbody = "Hello,\r\n\nTo nominate part(s) to be added to the Standard Parts Catalogue, please provide the full list of parts with the corresponding Product " +
            "lines using the 'PART ADDITION TEMPLATE' file.\r\nPlease be aware that only standard parts will be considered. Standard parts are parts which:\r\n  1. Appear in Enovia\r\n  " +
            "2. Require no additional engineering after initial procurement\r\n  3. Can be sourced from a single supplier\r\n  4. Have an active sales history or expected " +
            "active sales forecast.\r\n\r\nThank you for your request!\r\n\r\n";
        window.open("mailto:" + spcAdminEmail + "?subject=" + mailSubject + "&body=" + encodeURIComponent(emailbody), '_self');
    }

    public addFeedbackModal = () => {

        const modalConfig = {
            title: 'Provide Feedback',
            slotComponent: <SpcFeedback_ModalContent />,
            buttonConfig: [
                {
                    type: 'action',
                    label: 'Share your Feedback',
                    action: () => window.open("https://forms.office.com/e/pt0snb0HbV", "_blank", "noopener")
                }]
        }
        this.rootStore.mainStore.setModalConfig(modalConfig)
        this.rootStore.mainStore.setModalDisplayState(true)
    }

    public addNominatePartModal = () => {

        const modalConfig = {
            title: 'Nominate to SPC ?',
            slotComponent: <NominatePartModalContent />,
            buttonConfig: [
                {
                    type: 'action',
                    class: 'fls-button fls-button--primary-with-icon-outline',
                    label: 'Submit Parts',
                    action: () => this.TriggerOutlook()
                },
                {
                    type: 'action',
                    label: 'Download Template',
                    action: () => window.open("../../Part_Addition_Template.xlsx", "_blank")
                }]
        }
        this.rootStore.mainStore.setModalConfig(modalConfig);
        this.rootStore.mainStore.setModalDisplayState(true);
    }
}