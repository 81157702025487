

export const LoadingSpinner = () => {
    return (
        <svg className="fls-spinner">
            <circle viewBox="0 0 60px 60px" cx="20" cy="20" r="16"></circle>
        </svg>
    )
}

