import React, { useState, useRef, KeyboardEvent } from 'react'
import { useOnClickOutside } from '../../../foundation/methods/custom-hooks/useOnClickOutside'
import { Location } from '../../../features/ApiClient/ApiClient'

interface IProps<T> {
    data: T[],
    callBack: Function
}

const chevron = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="6 15 12 9 18 15" /></svg>`

export const SelectInput = <T extends Location>(props: IProps<T>) => {
    const { data, callBack } = props
    const selectInput = useRef(null)
    const [dropdownStatus, setDropdownStatus] = useState(false)
    const startSelectedValue = (data: T[]) => { return data[0].label === "Select Location..." ? data.length === 2 || (data.length >= 2 && "GLOBAL".localeCompare(data[1].label!, undefined, { sensitivity: 'base' }) === 0) ? data[1].label : data[0].label : data[0].label } // SelectInput has its own state. 
    const [currentSelectedValue, setCurrentSelectedValue] = useState<string | undefined>(startSelectedValue(data)) // To make sure the selectedValue updates with the Detailpage Location we set the state here aswell.

    const isKeyPressedEnterOrSpace = (event: KeyboardEvent, callBack: Function) => {
        if (event.code === 'Enter' || event.code === 'Space') {
            callBack()
        }
    }

    const setNewSelected = (option: T) => {
        setCurrentSelectedValue(option.label)
        callBack(option.value)
        setDropdownStatus(false)
    }

    const toggleDropdown = (event: KeyboardEvent) => {
        if (event.code === 'Enter' || event.code === 'Space') {
            setDropdownStatus((prevState) => prevState = !prevState)
        }
    }

    useOnClickOutside(selectInput, () => setDropdownStatus(false))

    return (
        <div className="fls-select" ref={selectInput} tabIndex={0} onKeyUp={(e) => toggleDropdown(e)}>
            <div className="fls-select__selected" onClick={() => setDropdownStatus((prevState) => prevState = !prevState)}>
                <span className="fls-font__medium-bold">{currentSelectedValue}</span>
                <div className={"fls-select__chevron " + (dropdownStatus ? '' : 'fls-select__chevron--rotated')} dangerouslySetInnerHTML={{ __html: chevron}}></div>
            </div>
            {dropdownStatus ? 
                <div className="fls-select__content-container">
                    <ul className="fls-select__content-list">
                        {data.map((option, index) => {
                            return (
                                <li className={"fls-font__small-bold fls-select__content-list-item " + (option.value === currentSelectedValue ? 'fls-select__content-list-item--selected' : '')} 
                                    key={index} 
                                    tabIndex={0}
                                    onKeyUp={(e) => isKeyPressedEnterOrSpace(e, () => setNewSelected(option))}
                                    onClick={() => setNewSelected(option)}>
                                        {(option.label ?? "") + (option.showDetails ? " (" + option.details + ")" : "")}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                :
                null
            }
        </div>
    )
}