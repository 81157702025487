import { ReactNode, useRef, useState } from 'react';
import { CellData, ILabelAndValue } from '../../features/ApiClient/ApiClient';
import { useOnClickOutside } from '../../foundation/methods/custom-hooks/useOnClickOutside';
import { deleteIcon } from '../../foundation/svg/delete-icon';
import { editIcon } from '../../foundation/svg/edit-icon';
import { exportIcon } from '../../foundation/svg/export-icon';
import { settingsIcon } from '../../foundation/svg/settings-icon';
import { spyGlassIcon } from '../../foundation/svg/spyglass-icon';
import { Action, IDataTableProps, ILink, ITableCell, ITableCellButton, ITableCellButtonModalProps } from './DataTableModels';

const IGNORED_KEY: string = "uuid";

export const DataTable = <T extends unknown>({tableTitle, tableHeaders, tableData, handlers} : IDataTableProps<T>) => {
    const keysOfTableData = Object.keys((tableData.rowData?.length ? tableData.rowData[0] : []) as object)
        .filter((key) => key !== IGNORED_KEY);
    
    return (<table className="fls-data-table">
        <caption className="fls-font__medium-bold fls-data-table__caption">{tableTitle}</caption>
        <thead>
            <tr className="fls-data-table__row">
                {keysOfTableData.map((rowData, index: number) => {
                    /**
                     * The keys of the tableData's object properties, match the key property in the objects in tableHeaders.
                     * By matching these values, the render sequence is no longer connected to the data sequence in tableHeaders.
                     */
                    const matchingHeaderForColumn = tableHeaders.find((header: ILabelAndValue) => header.value === rowData) 
                    const alignRightHeaderClass = () => matchingHeaderForColumn?.textAlignRight ? 'fls-data-table__cell--align-right' : 'fls-data-table__header'

                    return <th className={"fls-font__small-bold fls-data-table__header" + " " + alignRightHeaderClass()} key={index} scope="col">{matchingHeaderForColumn?.label}</th>
                })}
            </tr>
        </thead>

        <tbody>
            {tableData.rowData?.map((tableRow: any, index: number) => {
                return (<tr className="fls-data-table__row" key={index}>
                    {Object.entries(tableRow)
                        .filter(([key, _]) => {
                            return key !== IGNORED_KEY;
                        })
                        .map(([_, value], index: number) => {
                            return <TableCell key={index} tableCell={value} handlers={handlers} collectionId={tableRow.uuid} />
                        })}
                </tr>
                )
            })}
        </tbody>
    </table>);
}

const TableCell = <T extends unknown>({ tableCell, collectionId, handlers }: ITableCell<T>) => {
    /**
     * Tablecell's returned JSX element is determined by type. 
     * Falsy values renders '-'
     * String values renders tableCells string value
     * Objects contaning an url property renders <a> tag
     * Arrays symbolises a list of buttons, which renders a list of <button>
     */

    let computedCellValue: JSX.Element | JSX.Element[] | string = '-'
    const isTableCellArray = !!(Array.isArray(tableCell))
    const isTableCellString = !!(typeof tableCell === 'string')
    let isTableCellTextAlignRight: boolean | undefined
    let isTableCellNoBreak: boolean | undefined
    const alignRightCellClass = () => isTableCellTextAlignRight ? 'fls-data-table__cell--align-right' : ''
    const buttonCellClass = () => isTableCellArray ? 'fls-data-table__cell--button-container' : ''
    const noBreakCellClass = () => isTableCellNoBreak ? 'fls-data-table__cell--nobreak' : ''
  
    if (isTableCellString) {
        computedCellValue = tableCell;
    }

    const isTableCellLinkObject = (tableCell: ILink | T): tableCell is ILink => {
        return tableCell !== null && (tableCell as ILink).value !== undefined
    }

    const isTableCellObject = (tableCell: CellData | ILink | T): tableCell is CellData => {
        return tableCell !== null && (tableCell as CellData).value !== undefined
    }

    if (isTableCellObject(tableCell)) {
        isTableCellTextAlignRight = tableCell.textAlignRight
    }

    if (isTableCellObject(tableCell)) {
        isTableCellNoBreak = tableCell.noBreakValue
    }

    if (isTableCellLinkObject(tableCell)) {
        computedCellValue = tableCell.url ? <a className="fls-link" href={tableCell?.url ?? ''}>{tableCell?.value ?? '-'}</a> : <span>{tableCell.value}</span>
    }

    if (isTableCellArray) {
        const buttonList = tableCell.map((buttons: ITableCellButton, index: number) => <TableCellButtonModal tableCellData={buttons} collectionId={collectionId} handlers={handlers} key={index} />)
        computedCellValue = <div className="fls-data-table__edit-button-container">
            {buttonList}
        </div>
    }

    return <td className={"fls-font__small-regular fls-data-table__cell " + buttonCellClass() + " " + alignRightCellClass() + " " + noBreakCellClass() }>{computedCellValue}</td>
}

const TableCellButtonModal = ({ tableCellData, collectionId, handlers }: ITableCellButtonModalProps) => {
    const buttonModal = useRef(null)
    const [active, setActive] = useState(false)

    const iconDict: Record<Action, ReactNode> = {
        "export": exportIcon() as ReactNode,
        "edit": settingsIcon() as ReactNode,
        "delete": deleteIcon() as ReactNode,
        "rename": editIcon() as ReactNode,
        "open": spyGlassIcon() as ReactNode,
    }

    const toggleSettingsModal = () => {
        setActive(true)
    }

    const handleAction = (typeOfAction: Action) => {
        if (typeOfAction === "delete") {
            handlers.deleteCollection!(collectionId);
        }
        if (typeOfAction === "rename") {
            handlers.renameCollection!(collectionId);
        }
        if (typeOfAction === "export") {
            handlers.exportCollection!(collectionId);
        }
        if (typeOfAction === "open") {
            handlers.openCollection!(collectionId);
        }
    }

    const modalItemClass = (typeOfAction: Action) => {
        return typeOfAction === 'delete' ? `fls-data-table__edit-button-mini-modal-item--delete` : ''
    }

    useOnClickOutside(buttonModal, () => setActive(false))

    return (
        <div className="fls-data-table__edit-button-mini-modal-container">

            <button onClick={(tableCellData.name === 'export' || tableCellData.name === 'open') ? () => handleAction(tableCellData.name) : () => toggleSettingsModal()} className="fls-data-table__edit-button">
                <span>{iconDict[tableCellData.name as keyof typeof iconDict]}</span>
                <p>{tableCellData.name}</p>
            </button>

            <div ref={buttonModal} className={"fls-data-table__edit-button-mini-modal " + (active ? 'fls-data-table__edit-button-mini-modal--active' : '')}>
                {tableCellData.options && tableCellData.options.map((option: { name: Action }, index: number) => {
                    return (
                        <button key={index} className={"fls-data-table__edit-button-mini-modal-item " + modalItemClass(option.name)}
                            onClick={() => handleAction(option.name)}>
                            <span>{iconDict[option.name as keyof typeof iconDict]}</span>
                            <p className="fls-font__small-regular">{option.name}</p>
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
