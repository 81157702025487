import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
    useMsalAuthentication,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    MsalProvider,
} from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import AppRoutes from './routes/AppRoutes'
import { Footer } from './components/Footer/Footer'
import { NavMenu } from './components/NavMenu/NavMenu'
import './main.scss'
import { msalInstance } from './Auth'
import { fetchData } from './foundation/methods/fetch'
import { useStores } from './stores/StoreDistributor'
import { observer } from 'mobx-react';
import { ModalRootWrapper } from './components/Modal/ModalRootWrapper'

const AppWithAuthentication = observer(() => {
    const { mainStore } = useStores()
   useMsalAuthentication(InteractionType.Redirect);
    return <div style={{ backgroundColor: 'transparent' }}>
        <AuthenticatedTemplate>
            <NavMenu />
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route
                    return <Route key={index} {...rest} element={element} />
                })}
            </Routes>
            <Footer />
            {mainStore.modalDisplayState && mainStore.modalConfig ? <ModalRootWrapper {...mainStore.modalConfig}/> : ''}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <p>You are not signed in! Please sign in.</p>
        </UnauthenticatedTemplate>
    </div>
})

export const App = observer(() => {
    const clientSettingApiUrl = "/api/Environment/ClientSettings"
    const { mainStore } = useStores()
    
    useEffect(() => {
        fetchData(clientSettingApiUrl).then(clientSettings => {
            mainStore.setClientSettingsGlobally(clientSettings)
            window.clientSettings = clientSettings
        })  
    }, [])
    
    
    if (mainStore.clientSettings) {
        return <MsalProvider instance={msalInstance(mainStore.clientSettings).publicClientApplication}>
            <AppWithAuthentication />
        </MsalProvider>
    } else {
        <React.Fragment></React.Fragment>
    }
})

