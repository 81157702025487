import React from 'react'
import { Link } from 'react-router-dom'

interface InPageError_PropsButton {
    type: string
    name: string
    url: string
    callback?: Function
}

export interface InPageError_Props {
    errorType: string
    errorCode: string
    buttons: InPageError_PropsButton[]
}

export const InPageError = (props : InPageError_Props) => {
    const { buttons, errorCode, errorType } = props
    const buttonClass = (buttonType:string) => `fls-button--${buttonType}`

    const errorDescriptionByStatus = (errorStatus:string) => {
        const knownErrorCodesDict = {
            "503" : "The service is currently unavailable. Please try again later.",
            "500" : "Something went wrong.",
            "404" : "The ressource you are looking for does not exist.",
            "403" : "You unfortunately does not have permission to view this ressouce.",
        }
        return knownErrorCodesDict[(errorStatus ?? "500") as keyof typeof knownErrorCodesDict]
    }

    return (
        <section className="fls-in-page-error">
            <div className="fls-in-page-error__wrapper">
                <div className="fls-in-page-error__container">
                    <span className="fls-font__medium-bold">
                        <div className="fls-in-page-error__icon">
                        <svg id="Risk_management_dark_blue_web_and_ppt" data-name="Risk management_dark blue_web and ppt" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100">
                            <rect id="Rectangle_1718" data-name="Rectangle 1718" width="50" height="50" fill="none"/>
                            <g id="Group_993" data-name="Group 993">
                                <path id="Path_1235" data-name="Path 1235" d="M53.03,68.91H46.97a.619.619,0,0,1-.61-.61V62.23a.619.619,0,0,1,.61-.61h6.06a.606.606,0,0,1,.61.61V68.3a.6.6,0,0,1-.61.61" fill="none" stroke="#002a54" strokeWidth="2.5"/>
                                <path id="Path_1236" data-name="Path 1236" d="M53.03,58.11H46.97a.61.61,0,0,1-.61-.6V41.01a.61.61,0,0,1,.61-.6h6.06a.6.6,0,0,1,.61.6v16.5a.6.6,0,0,1-.61.6" fill="none" stroke="#002a54" strokeWidth="2.5"/>
                                <path id="Path_1237" data-name="Path 1237" d="M75.9,75H24.1a2.783,2.783,0,0,1-2.41-1.37,2.727,2.727,0,0,1,0-2.76L47.6,26.39a2.767,2.767,0,0,1,4.8,0L78.3,70.87a2.727,2.727,0,0,1,0,2.76A2.766,2.766,0,0,1,75.9,75" fill="none" stroke="#002a54" strokeWidth="2.5"/>
                            </g>
                            </svg>
                            <p>Error code:</p>
                        </div>
                    </span>
                    <h1 className="fls-font__xl-title">{errorCode} {errorType && `- ${errorType}`}</h1>
                    <p className="fls-font__large-regular">{errorDescriptionByStatus(errorCode)}</p>
                    <div className="fls-in-page-error__button-container">
                        {buttons.map((button, index) => {
                            return button.url ? 
                                <Link key={index} className={'fls-button ' + buttonClass(button.type)} to={button.url}>{button.name}</Link>
                                :
                                <button key={index} className={'fls-button ' + buttonClass(button.type)} onClick={() => button.callback ? button.callback() : window.location.reload()}>{button.name}</button>
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}