import { InPageError } from "../features/InPageError/InPageError.tsx";
import { LandingPage } from "../features/LandingPage/LandingPage.page.tsx";
import { SparePartSavedSearchesPage } from "../features/SparePartCollection/SparePartSavedSearches.page.tsx";
import { SparePartDetailPage } from "../features/SparePartDetail/SparePartDetail.page.tsx";
import { SparePartFilterPage } from "../features/SparePartFilter/SparePartFilter.page.tsx";
import { SystemDetailsPage } from "../features/SystemDetails/SystemDetails.page";

const inPageErrorConfig = {
    errorType : "Not found",
    errorCode : "404",
    buttons : [
        {
            type : "primary",
            name : "Return home",
            url : "/",
        },
        {
            type : "",
            name : "Refresh page",
            url : "",
        }
    ]
}

const AppRoutes = [
    {
        path: "/",
        index: true,
        element: <LandingPage />,
    }, {
        name: "Filter",
        path: '/filter',
        element: <SparePartFilterPage />,
    }, {
        path: '/part/*',
        element: <SparePartDetailPage />,
    }, {
        name: "Saved searches",
        path: '/savedSearches',
        element: <SparePartSavedSearchesPage />,
    }, {
        path: '*',
        element: <InPageError {...inPageErrorConfig}/>,
    }, {
        path: "/system-details",
        element: <SystemDetailsPage />,
    }
]

export default AppRoutes
