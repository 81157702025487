import { FacetRequest, IFacetCategory, SearchRequest } from "../../features/ApiClient/ApiClient";
import { FacetCategoryDisplay } from "../../stores/FacetStore";

export type SelectedFacets = Record<string, string[]>;

export const facetCategoriesToFacetRequests = (facetCategories: FacetCategoryDisplay[]): FacetRequest[] => {
    return facetCategories
        .flatMap((facetCategory) => {
            return facetCategory.facetDropdowns;
        })
        .map((facet) => {
            return new FacetRequest({
                facetName: facet.name,
                facetValues: facet.facets
                    ?.filter((facetValue) => facetValue.selected)
                    ?.map((facetValue) => facetValue.value),
            })
        });
}

export const facetCategoriesToSearchRequest = (searchString: string, filterFacetCategories: FacetCategoryDisplay[]): SearchRequest => {
    let facetRequests = facetCategoriesToFacetRequests(filterFacetCategories);

    // Dont save page number and items per page as part of saved search
    return new SearchRequest({
        searchString: searchString,
        facetRequests: facetRequests,
    });
}

export const facetCategoriesToChosenFacets = (facetCategories: IFacetCategory[]): SelectedFacets => {

    let result: SelectedFacets = {};

    facetCategories
        .flatMap(category => category.facets!)
        .forEach(facet => {
            const values = facet.facetValues!
                .filter(facetValue => facetValue.selected && facetValue.value !== undefined)
                .map(facetValue => facetValue.value!);

            if (facet.name !== undefined && values.length !== 0) {
                result[facet.name] = values;
            }
        });

    return result;
}

export const facetRequestsToChosenFacets = (facetRequests: FacetRequest[]): SelectedFacets => {

    let result: SelectedFacets = {};

    facetRequests.forEach(facet => {
        const values = facet.facetValues!
            .map(facetValue => facetValue);

        if (facet.facetName !== undefined && values.length !== 0) {
            result[facet.facetName] = values;
        }
    });

    return result;
}
