import { IExternalLinks } from "../../features/ApiClient/ApiClient";

export const ExternalLinks = (externalLink: IExternalLinks) => {
    const {
        gpsturl,
        gpstDescription,
        proposalManagementURL,
        proposalManagementDescription,
        addMailDescription,
        spcAdminEmail,
        mailSubject,
        mailBody
    } = externalLink;

    const TriggerOutlook = () => {
        var emailbody = "";
        if (mailBody != undefined) { emailbody = mailBody.toString(); }
        window.open("mailto:" + spcAdminEmail + "?subject=" + mailSubject + "&body=" + encodeURIComponent(emailbody), '_self');
    }

    return (
        <div className="fls-ext-card__wrapper" aria-describedby={"health-popover"}>
            <div>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td className="fls-ext-card__link-info" onClick={() => window.open(gpsturl, '_blank')}>Global Parts Search Tool</td>
                            <td className="fls-ext-card__detail-info">{gpstDescription}</td>
                        </tr>
                        <tr className="fls-ext-card__empty-row"></tr>
                        <tr >
                            <td className="fls-ext-card__link-info" onClick={() => window.open(proposalManagementURL, '_blank')}>Proposal Support Tool</td>
                            <td className="fls-ext-card__detail-info">{proposalManagementDescription}</td>
                        </tr>
                        <tr className="fls-ext-card__empty-row"></tr>
                        <tr >
                            <td className="fls-ext-card__link-info" onClick={() => TriggerOutlook()}>Add to SPC ?</td>
                            <td className="fls-ext-card__mail-detail-info">{addMailDescription}
                                <p className="fls-ext-card__template">Part Addition Template : <a href='/Part_Addition_Template.xlsx' download >Download</a></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}