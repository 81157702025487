import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchTextInput } from "../../components/InputFields/SearchTextInput/SearchTextInput";
import { FLSlogo } from "../../foundation/svg/fls-logo";
import { FLSBoxlogo } from "../../foundation/svg/fls-box-logo";
import { useStores } from "../../stores/StoreDistributor";

export const LandingPage = observer(() => {
    const { partSearchStore } = useStores();

    const navigate = useNavigate();

    // Redraw the page when search string in search box has changed
    useEffect(() => { }, [partSearchStore.searchString]);

    return (<div className="fls-sp-search__overlay">
        <div className="fls-sp-search__overlay-content-wrapper">
            <div className="fls-sp-search__overlay-logo">{FLSBoxlogo(200)}</div>
            <h2 className="fls-font__medium-title fls-sp-search__overlay-title">Standard Parts Catalogue</h2>
            <SearchTextInput
                activationCallback={() => {
                    partSearchStore.updatePartSearch();
                    partSearchStore.setSearchParamsInUrl();
                    navigate(`/filter`);
                }}
                placeholder="Search standard part"
                autoFocus={true}
                searchStore={partSearchStore} />
            <a className="fls-sp-search__overlay-filter-page-title-link">
                <button className="fls-button fls-sp-search__overlay-filter-search-btn"
                    onClick={() => {
                        partSearchStore.searchString = "";
                        navigate(`/filter?pageSize=10&pageNumber=1&searchString=&facetFilters=e30%3D`);
                    }}>
                    <span>FILTER SEARCH</span>
                </button>
            </a>
        </div>
    </div>);
});
