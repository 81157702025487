import PageLink from '../../components/PageLink/PageLink';
import Select, { StylesConfig } from 'react-select';

export type PaginationProps = {
    currentPage: number;
    lastPage: number;
    maxLength: number;
    itemsPerPage: number;
    setCurrentPage: (page: number) => void;
    setItemsPerPage: (itemCount: number) => void;
};

export const ColourStyles: StylesConfig = {
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: 'white',
        "&:hover": {
            color: '#004FFF',
            border: '1px solid #004FFF'
        },
        fontFamily: 'ProximaNovaRegular',
        fontSize: 14,
        border: isFocused ? '1px solid #004FFF' : '1px solid #dee2e6',
        boxShadow: 'none',
        width: "max-content",
        minWidth: "100%",
        borderRadius: 0,
        height: 40,
        flexWrap: 'nowrap',
        color: isFocused ? '#004FFF' : undefined
    }),
    dropdownIndicator: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? '#004FFF' : undefined,
        "&:hover": {
            color: '#004FFF'
        }
    }),
    menu: base => ({
        ...base,
        marginBottom: 0,
        boxShadow: 'none',
        border: '1px solid #dee2e6',
        borderRadius: 0,
    }),
    singleValue: (styles, { isDisabled }) => ({ ...styles, color: isDisabled ? '#004FFF' : undefined }),
    option: (styles, { isDisabled, isSelected }) => {
        return {
            ...styles,
            "&:hover": {
                color: '#004FFF'
            },
            fontFamily: 'ProximaNovaRegular',
            fontSize: 14,
            backgroundColor: isDisabled
                ? undefined : 'white',
            color: isDisabled
                ? undefined : isSelected ? '#004FFF' : undefined,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? undefined : 'white'
            },
        };
    },
};

export default function Pagination({
    currentPage,
    lastPage,
    maxLength,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage
}: PaginationProps) {
    const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

    const SelectItemsPerPage = (event: any) => {
        const value = event.value;
        setItemsPerPage(value); setCurrentPage(1)
    }

    const selectOptions = [{ value: 100, label: "100" }, { value: 50, label: "50" }, { value: 25, label: "25" }, { value: 10, label: "10" }]
    return (
        <nav className="pagination" aria-label="Pagination">
            <Select
                isSearchable={false}
                value={{
                    value: itemsPerPage, label: itemsPerPage + ""
                }}
                onChange={SelectItemsPerPage}
                options={selectOptions}
                styles={ColourStyles}
                menuPlacement='top'
                components={{
                    IndicatorSeparator: () => null
                }}
            />
            <PageLink
                className="fls-font__medium-regular"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}>
                Previous
            </PageLink>
            <PageLink
                className="fls-font__small-regular"
                active={currentPage === 1}
                onClick={() => setCurrentPage(1)}>{1}
            </PageLink>
            {pageNums.map((pageNum, idx) => (
                <PageLink
                    className="fls-font__small-regular"
                    key={idx}
                    active={currentPage === pageNum}
                    disabled={isNaN(pageNum)}
                    onClick={() => setCurrentPage(pageNum)}>
                    {!isNaN(pageNum) ? pageNum : '...'}
                </PageLink>
            ))}
            {lastPage > 1 &&
                <PageLink
                    className="fls-font__small-regular"
                    active={currentPage === lastPage}
                    onClick={() => setCurrentPage(lastPage)}>{lastPage}
                </PageLink>
            }
            <PageLink
                className="fls-font__small-regular"
                disabled={currentPage === lastPage}
                onClick={() => setCurrentPage(currentPage + 1)}>
                Next
            </PageLink>
        </nav>
    );
}

export function getPaginationItems(
    currentPage: number,
    lastPage: number,
    maxLength: number
) {
    const res: Array<number> = [];
    if (lastPage <= maxLength + 2) {
        for (let i = 2; i <= lastPage - 1; i++) {
            res.push(i);
        }
    }

    // handle ellipsis logics
    else {
        const firstPage = 1;
        const sideLength = Math.ceil(maxLength / 2);

        // handle ellipsis in the middle
        if (currentPage <= 2 || currentPage >= lastPage - 1) {
            for (let j = 2; j <= sideLength + firstPage; j++) {
                res.push(j);
            }
            res.push(NaN);
            for (let k = lastPage - sideLength; k <= lastPage - 1; k++) {
                res.push(k);
            }
        }// handle two ellipsis
        else {
            if (currentPage !== firstPage + 2) {
                res.push(NaN);
            }
            res.push(currentPage - 1)
            res.push(currentPage)
            res.push(currentPage + 1)
            if (currentPage !== lastPage - 2) {
                res.push(NaN);
            }
        }
    }
    return res;
}
