import { makeAutoObservable } from 'mobx';
import { IModalRootWrapperProps } from '../components/Modal/ModalRootWrapper';
// Get interfaces/types from ApiClient

const setFreezeBackgroundState = (state: boolean) => {
    const body = document.querySelector('body');
    if (state) {
        body?.classList.add('no-scroll');
    } else {
        body?.classList.remove('no-scroll');
    }
}

export default class MainStore {
    modalConfig?: IModalRootWrapperProps = undefined;
    modalDisplayState = false;
    clientSettings: { tenantId: string, clientId: string } | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    // Action
    public setClientSettingsGlobally = (clientSettings: { tenantId: string, clientId: string }) => {
        this.clientSettings = clientSettings;
    }

    // Action
    public setModalConfig = (modalConfig: IModalRootWrapperProps) => {
        this.modalConfig = modalConfig;
    }

    // Action
    public setModalDisplayState = (newState: boolean) => {
        setFreezeBackgroundState(newState);
        this.modalDisplayState = newState;
    }
}
