import { makeAutoObservable } from "mobx";
import { Facet, FacetRequest, IFacetCategory } from "../features/ApiClient/ApiClient";
import { SelectedFacets } from "../foundation/methods/facetCategoriesToSearchRequest";

export class FacetValueSelector {
    constructor(value: string, label: string, totalCount: number, selected: boolean = false) {
        this.value = value;
        this.label = label;
        this.totalCount = totalCount;
        this.selected = selected;
    }

    readonly value: string;
    readonly label: string;
    readonly totalCount: number;
    matches?: number;
    selected: boolean;

    toggleSelected = () => {
        this.selected = !this.selected;
    }

    setSelected = (selected: boolean) => {
        this.selected = selected;
    }

    setMatches = (matches: number) => {
        this.matches = matches;
    }
}

export class FacetDropdown {
    constructor(name: string, label: string, dynamicSortable: boolean = false, facets: FacetValueSelector[], open: boolean = false,) {
        this.name = name;
        this.label = label;
        this.dynamicSortable = dynamicSortable;
        this.open = open;
        this.facets = facets;
    }

    readonly name: string;
    readonly label: string;
    dynamicSortable: boolean;
    open: boolean;
    facets: FacetValueSelector[];

    toggleOpen = () => {
        this.open = !this.open;
    }

    setOpen = (open: boolean) => {
        this.open = open;
    }

    sortMatches = () => {
        this.facets.sort((a, b) => {            
            if (b.selected !== a.selected && b.selected) return 1;
            if (b.selected !== a.selected && a.selected) return -1;
            else {
                if ((b.matches ?? 0) < (a.matches ?? 0)) return -1;
                if ((b.matches ?? 0) > (a.matches ?? 0)) return 1;
                else {
                    if (b.label < a.label) return -1;
                    if (b.label > a.label) return 1;
                    return 0;
                }
            }
        });
    }
}

export class FacetCategoryDisplay {
    constructor(name: string, facets: FacetDropdown[]) {
        this.name = name;
        this.facetDropdowns = facets;
    }

    readonly name: string;
    facetDropdowns: FacetDropdown[];

    getFacetDropdown = (facetName: string): FacetDropdown | undefined =>
        this.facetDropdowns.find(f => f.name === facetName);
}

export default class FacetStore {

    static CreateFacetStore = (facetCategories: IFacetCategory[]): FacetStore => {
        return new FacetStore(
            facetCategories.map(fc => new FacetCategoryDisplay(
                fc.name ?? "",
                (fc.facets ?? []).map(f => new FacetDropdown(
                    f.name ?? "",
                    f.displayName ?? "",
                    f.dynamicSorting ?? false,
                    (f.facetValues ?? []).map(fv => new FacetValueSelector(
                        fv.value ?? "",
                        fv.displayValue ?? "",
                        fv.totalCount ?? 0
                    ))
                ))
            ))
        );
    }

    constructor(facetCategories: FacetCategoryDisplay[]) {
        this.facetCategories = facetCategories;
        makeAutoObservable(this);
    }

    facetCategories: FacetCategoryDisplay[];

    isFacetOpen = (facetName: string): boolean => {
        return this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(f => f.name === facetName)
            ?.open ?? false;
    }

    toggleFacetDropdown = (facetName: string) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet?.name === facetName)
            ?.toggleOpen();
    }

    setFacetDropdown = (facetName: string, open: boolean) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet?.name === facetName)
            ?.setOpen(open);
    }

    isFacetValueChecked = (facetName: string, facetValue: string): boolean => {
        return this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet.name === facetName)
            ?.facets
            .find(facetVal => facetVal.value === facetValue)
            ?.selected ?? false;
    }

    toggleSelectedFacetValue = (facetName: string, facetValue: string) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet.name === facetName)
            ?.facets
            .find(facetVal => facetVal.value === facetValue)
            ?.toggleSelected();
    }

    setSelectedFacetValue = (facetName: string, facetValue: string, selected: boolean) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet.name === facetName)
            ?.facets
            .find(facetVal => facetVal.value === facetValue)
            ?.setSelected(selected);
    }

    setAllUnselected = () => {
        this.facetCategories.flatMap(fc => fc.facetDropdowns)
            .flatMap(fc => fc.facets)
            .forEach(fc => fc.setSelected(false))
    }

    getFacetRequest = (): FacetRequest[] => {
        return this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .map(f => new FacetRequest({
                facetName: f.name,
                facetValues: f.facets
                    .filter(fv => fv.selected)
                    .map(fv => fv.value),
            }));
    }

    getSelectedFacets = (): SelectedFacets => {
        let result: SelectedFacets = {};

        if (this.facetCategories)
        {
            this.facetCategories
                .flatMap(fc => fc.facetDropdowns)
                .forEach(facet => {
                    const values = facet.facets!
                        .filter(facetValue => facetValue.selected && facetValue.value !== undefined)
                        .map(facetValue => facetValue.value!);

                    if (facet.name !== undefined && values.length !== 0) {
                        result[facet.name] = values;
                    }
                });
        }
        return result;
    }

    updateFacets = (updatedFacets: Facet[]) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns.map(dropDown => ({ category: fc.name, facetDropdown: dropDown })))
            .forEach(({ category, facetDropdown }) => {
                facetDropdown
                    .facets
                    .forEach(selector => {
                        const matches = updatedFacets
                            .find(f => f.name === facetDropdown.name)
                            ?.facetValues
                            ?.find(fv => fv.value === selector.value)
                            ?.matches ?? 0
                        selector.setMatches(matches);
                    });

                if (facetDropdown.dynamicSortable) {
                    facetDropdown.sortMatches();
                }
            });
    }

    setMatches = (facetName: string, facetValue: string, matches: number) => {
        this.facetCategories
            .flatMap(fc => fc.facetDropdowns)
            .find(facet => facet.name === facetName)
            ?.facets
            .find(facetVal => facetVal.value === facetValue)
            ?.setMatches(matches);
    }
}
