import { makeAutoObservable } from "mobx";
import { ExportCollectionComplete_ModalContent, ExportCollectionFailed_ModalContent, ExportCollectionInProgress_ModalContent } from "../components/Modal/ModalComponents";
import { IModalRootWrapperProps } from "../components/Modal/ModalRootWrapper";
import { Client, ExportStatus, ExportStatusRequest, SearchRequest } from "../features/ApiClient/ApiClient";
import { RootStore } from "./StoreDistributor";

class ActiveExport {
    id!: string;
    exportTimeout!: NodeJS.Timeout;
    downloadUri?: string;
}

export default class ExportStore {
    private static readonly exportRefetchIntervalMs: number = 1000;

    rootStore: RootStore;
    activeExport?: ActiveExport = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    public startExportOnSearchString = (searchString: string) => {
        const searchRequest: SearchRequest = new SearchRequest({
            searchString: searchString,
        });

        this.startExport(searchRequest);
    }

    // Action
    public startExport = (searchRequest: SearchRequest) => {
        const modalConfig: IModalRootWrapperProps = {
            title: "Export",
            slotComponent: <ExportCollectionInProgress_ModalContent />,
            buttonConfig: [
                {
                    type: "cancel",
                    label: "Cancel",
                    action: () => {
                        this.stopExport();
                    },
                }
            ],
            modalClosedCallback: this.stopExport,
        };

        this.rootStore.mainStore.setModalConfig(modalConfig);
        this.rootStore.mainStore.setModalDisplayState(true);

        let apiClient = new Client();

        apiClient.export(searchRequest)
            .then((exportId: string) => {
                this.activeExport = {
                    id: exportId,
                    exportTimeout: setTimeout(this.fetchExportStatus, ExportStore.exportRefetchIntervalMs),
                };

            }, (_reject) => {
                console.log(_reject);
                this.stopExport();
            });
    }

    // Action
    public stopExport = () => {
        clearTimeout(this.activeExport?.exportTimeout);
        this.activeExport = undefined;
        this.rootStore.mainStore.setModalDisplayState(false);
    }

    // Action
    public fetchExportStatus = () => {
        let apiClient = new Client();

        if (this.activeExport == null) {
            console.warn("Export is no longer active");
            return;
        }

        apiClient.status(new ExportStatusRequest({ exportId: this.activeExport.id }))
            .then((exportStatus: ExportStatus) => {
                if (exportStatus.exportExists) {
                    if (this.activeExport == null) { return; }

                    if (exportStatus.exportIsComplete) {
                        this.activeExport.downloadUri = exportStatus.downloadUri;
                        this.exportComplete();
                    } else {
                        // Check again later
                        this.activeExport.exportTimeout = setTimeout(this.fetchExportStatus, ExportStore.exportRefetchIntervalMs);
                    }
                } else {
                    this.exportFailed();
                }
            });
    }

    // Action
    public exportComplete = () => {
        const modalConfig: IModalRootWrapperProps = {
            title: "Export complete",
            slotComponent: <ExportCollectionComplete_ModalContent />,
            buttonConfig: [{
                type: 'confirm',
                label: 'Download',
                action: () => {
                    this.downloadExport();
                    this.stopExport();
                }
            }],
            modalClosedCallback: this.stopExport,
        };

        this.rootStore.mainStore.setModalConfig(modalConfig);
        this.rootStore.mainStore.setModalDisplayState(true);

        // Download on completion
        this.downloadExport();
    }

    // Action
    public exportFailed = () => {
        if (this.activeExport == null) { return; }

        const modalConfig: IModalRootWrapperProps = {
            title: "Export Failed",
            slotComponent: <ExportCollectionFailed_ModalContent />,
            buttonConfig: [{
                type: 'cancel',
                label: 'Close',
                action: () => {
                    this.stopExport();
                }
            }],
            modalClosedCallback: this.stopExport,
        };

        this.rootStore.mainStore.setModalConfig(modalConfig);
        this.rootStore.mainStore.setModalDisplayState(true);
    }

    public downloadExport = () => {
        if (this.activeExport?.downloadUri != null) {
            // Open with _blank and noopener to avoid page freeze
            // https://stackoverflow.com/questions/34957480/when-using-window-open-if-the-new-window-freeze-so-too-does-the-parent
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Window_features
            window.open(this.activeExport.downloadUri, "_blank", "noopener");
        }
    }
}
