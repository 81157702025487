import React from 'react'

interface IButtonProps {
    disabled?: boolean
    type: string,
    icon?: JSX.Element,
    text: string,
    action: Function,
}

export const Button = ({type, icon, text, action, disabled} : IButtonProps) => {
    return (
        <button disabled={disabled} className={"fls-button " + (`fls-button--${type}`)} onClick={() => action()}>
            {icon ? 
                <div className="fls-button__icon-container">{icon}</div>
                :
                null
            }
            <span>{text}</span>
        </button>
    )
}
