export const exportIcon = () => {
    return (
        <svg
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_229_11333)">
                <path
                    d="M3.85352 9.20825V10.2916C3.85352 10.5789 3.96843 10.8545 4.17297 11.0576C4.37751 11.2608 4.65492 11.3749 4.94418 11.3749H11.4882C11.7775 11.3749 12.0549 11.2608 12.2594 11.0576C12.464 10.8545 12.5789 10.5789 12.5789 10.2916V9.20825"
                    stroke="#062549"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.48926 5.95825L8.21593 8.66659L10.9426 5.95825"
                    stroke="#062549"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.21582 2.16675V8.66675"
                    stroke="#062549"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_229_11333">
                    <rect
                        width="13.088"
                        height="13"
                        fill="white"
                        transform="translate(0.665039)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
