import React, { useState, useRef, KeyboardEvent } from 'react'
import { useOnClickOutside } from '../../foundation/methods/custom-hooks/useOnClickOutside'

export const TabsSelector = (props: any) => {
    const { data, callBack } = props
    const selectInput = useRef(null)
    const [dropdownStatus, setDropdownStatus] = useState(false)

    const isKeyPressedEnterOrSpace = (event: KeyboardEvent, callBack: Function) => {
        if (event.code === 'Enter' || event.code === 'Space') {
            callBack()
        }
    }

    const toggleDropdown = (event: KeyboardEvent) => {
        isKeyPressedEnterOrSpace(event, () => {
            setDropdownStatus((prevState) => prevState = !prevState)
        });
    }

    const setNewSelectedTab = (option: any) => {
        callBack(option)
        setDropdownStatus(false)
    }

    useOnClickOutside(selectInput, () => setDropdownStatus(false))

    return (
        <div className="fls-tabs-selector" ref={selectInput} tabIndex={0} onKeyUp={(e) => toggleDropdown(e)}>
            <div className="fls-tabs-selector__selected" onClick={() => setDropdownStatus((prevState) => prevState = !prevState)}>
                <p className={"fls-tabs-selector__plus " + (dropdownStatus ? '' : 'fls-tabs-selector__plus--rotated')}>+</p>
            </div>
            {dropdownStatus ? 
                <div className="fls-tabs-selector__content-container">
                    <ul className="fls-tabs-selector__content-list">
                        {data?.map((option: any, index: number) => {
                            return (
                                <li className={"fls-font__small-bold fls-tabs-selector__content-list-item "} 
                                    key={index} 
                                    tabIndex={0}
                                    onKeyUp={(e) => isKeyPressedEnterOrSpace(e, () => setNewSelectedTab(option))}
                                    onClick={() => setNewSelectedTab(option)}>
                                    {(option.label ?? "") + (option.dataRowCount ? " (" + option.dataRowCount + ")" : "")}
                   
                                </li>
                            )
                        })}
                    </ul>
                </div>
                :
                null
            }
        </div>
    )
}