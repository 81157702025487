import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = (clientSettings : any) => {
    
    const msalConfig = {
        auth: {
            clientId: clientSettings.clientId,
            authority: `https://login.microsoftonline.com/${clientSettings.tenantId}`,
            redirectUri: window.location.protocol + "//" + window.location.host,
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        }
    };

    const loginRequest = {
        scopes: [`${clientSettings.clientId}/.default`],
    };

    return {
        publicClientApplication: new PublicClientApplication(msalConfig),
        loginRequest: loginRequest,
    }
}
