import Popper from "@mui/material/Popper";
import React from "react";
import { Link } from "react-router-dom";
import { ISparePartIndex } from "../../features/ApiClient/ApiClient";
import { toLowerCase } from "../../foundation/methods/toLowerCase";
import { DataHealthTrafficLight } from "../DataHealthTrafficLight/DataHealthTrafficLight";

export const SparePartCard = (sparePartIndex: ISparePartIndex) => {
    const {
        partNumber,
        model,
        productCode,
        description,
        partDataHealth,
        dataHealthStates,
    } = sparePartIndex;

    const dataHealthPoint = {
        state: toLowerCase(partDataHealth ?? "")
    }

    const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | undefined>(undefined);
    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => setPopoverAnchor(event.currentTarget);
    const handleMouseLeave = () => setPopoverAnchor(undefined);
    const encodedPartNumber = encodeURI(partNumber ?? "");

    return <Link
        className="fls-sp-card"
        to={`/part/${encodedPartNumber}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>

        <div className="fls-sp-card__wrapper" aria-describedby={"health-popover"}>

            <section className="fls-sp-card__primary-info">
                <div className="fls-sp-card__primary-info-wrapper">
                    <DataHealthTrafficLight {...dataHealthPoint} />
                    <h2 className="fls-font__xsmall-title">{partNumber}</h2>
                </div>

                <p className="fls-font__small-regular">{description}</p>
            </section>

            <section className="fls-sp-card__secondary-info">
                <div className="fls-sp-card__secondary-info-list-item">
                    <span className="fls-font__xsmall-regular">{"Product code"}</span>
                    <p className="fls-font__small-regular">{productCode || '-'}</p>
                </div>
                <div className="fls-sp-card__secondary-info-list-item">
                    <span className="fls-font__xsmall-regular">{"Model"}</span>
                    <p className="fls-font__small-regular">{model || '-'}</p>
                </div>
            </section>

            {!!dataHealthStates && <Popper
                id={"health-popover"}
                style={{ pointerEvents: "none" }}
                open={!!popoverAnchor}
                anchorEl={popoverAnchor}
                placement={"bottom-start"}
                modifiers={[{
                    name: "offset",
                    options: {
                        offset: ({ placement }: { placement: string }) => {
                            // Anchor point is one pixel higher than expected, we correct for that.
                            if (placement === "bottom-start") {
                                return [0, 6];
                            } else if (placement === "top-start") {
                                return [0, 4];
                            } else {
                                return [0, 5];
                            }
                        }
                    }
                }]}>

                <div className="fls-sp-card__data-health-hover">
                    <h2 className="fls-font__small-bold">{"Data Health"}</h2>

                    {dataHealthStates.map((healthState, i) =>
                        <DataHealthTrafficLight key={i} {...{ label: healthState.label, state: healthState.state }} />)}
                </div>

            </Popper>}
        </div>
    </Link>;
}
