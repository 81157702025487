
import React from 'react'
import * as ReactDOMServer from 'react-dom/server';

export const Footer = () => {
  
    return (
        <footer className="fls-footer">

        </footer>
    )
}